@use '@angular/material' as mat;

@include mat.core();

$dark-primary-text: #212121;
$dark-secondary-text: #858585;
$dark-secondary-text-2: #b3b3b3;
$dark-disabled-text: #b3b3b3;
$dark-dividers: rgba(black, 0.12);
$dark-focused: #edeff1;
$dark-icon: #a7afb8;

$light-primary-text: #ffffff;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$dark-background-2: #f7f8fa;
$dark-dividers-2: #d8dce1;

$tertiary-color: #0088FF;

:root {
  --dark-primary-text: #{$dark-primary-text};
  --dark-secondary-text: #{$dark-secondary-text};
  --dark-secondary-text-2: #{$dark-secondary-text-2};
  --dark-disabled-text: #{$dark-disabled-text};
  --dark-dividers: #{$dark-dividers};
  --dark-focused: #{$dark-focused};
  --dark-icon: #{$dark-icon};

  --dark-background-2: #{$dark-background-2};
  --dark-dividers-2: #{$dark-dividers-2};

  --light-primary-text: #{$light-primary-text};
  --light-secondary-text: #{$light-secondary-text};
  --light-disabled-text: #{$light-disabled-text};
  --light-dividers: #{$light-dividers};
  --light-focused: #{$light-focused};

  --tertiary-color: #{$tertiary-color};

  /** Primary color variants*/
  --primary-color: #0d4c90;
  --primary-color-50: #e2eaf2;
  --primary-color-100: #b6c9de;
  --primary-color-200: #86a6c8;
  --primary-color-300: #5682b1;
  --primary-color-400: #3167a1;
  --primary-color-500: var(--primary-color);
  --primary-color-600: #0b4588;
  --primary-color-700: #093c7d;
  --primary-color-800: #073373;
  --primary-color-900: #032461;
  --primary-color-A100: #91afff;
  --primary-color-A200: #5e8aff;
  --primary-color-A400: #2b65ff;
  --primary-color-A700: #1252ff;
  --primary-color-contrast-50: var(--dark-primary-text);
  --primary-color-contrast-100: var(--dark-primary-text);
  --primary-color-contrast-200: var(--dark-primary-text);
  --primary-color-contrast-300: var(--light-primary-text);
  --primary-color-contrast-400: var(--light-primary-text);
  --primary-color-contrast-500: var(--light-primary-text);
  --primary-color-contrast-600: var(--light-primary-text);
  --primary-color-contrast-700: var(--light-primary-text);
  --primary-color-contrast-800: var(--light-primary-text);
  --primary-color-contrast-900: var(--light-primary-text);
  --primary-color-contrast-A100: var(--dark-primary-text);
  --primary-color-contrast-A200: var(--dark-primary-text);
  --primary-color-contrast-A400: var(--dark-primary-text);
  --primary-color-contrast-A700: var(--dark-primary-text);
}

$app-blue: (
  50 : #e2eaf2,
  100 : #b6c9de,
  200 : #86a6c8,
  300 : #5682b1,
  400 : #3167a1,
  500 : #0d4c90,
  600 : #0b4588,
  700 : #093c7d,
  800 : #073373,
  900 : #032461,
  A100 : #91afff,
  A200 : #5e8aff,
  A400 : #2b65ff,
  A700 : #1252ff,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$app-warn: (
  50: #fde0e0,
  100: #fab3b3,
  200: #f68080,
  300: #f24d4d,
  400: #f02626,
  500: #ed0000,
  600: #eb0000,
  700: #e80000,
  800: #e50000,
  900: #e00000,
  A100: #ffffff,
  A200: #ffd4d4,
  A400: #ffa1a1,
  A700: #ff8888,
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$app-accent: (
  50: #e0e0e0,
  100: #b3b3b3,
  200: #808080,
  300: #4d4d4d,
  400: #262626,
  500: #000000,
  600: #000000,
  700: #000000,
  800: #000000,
  900: #000000,
  A100: #a6a6a6,
  A200: #8c8c8c,
  A400: #737373,
  A700: #666666,
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$primary1: mat.define-palette($app-blue);
$accent1: mat.define-palette($app-accent);
$warn: mat.define-palette($app-warn);

$app-typography: mat.define-typography-config();
@include mat.typography-hierarchy($app-typography);

$app-theme: mat.define-light-theme((color: (primary: $primary1,
        accent: $accent1,
        warn: $warn, ),
      density: 0,
    ));

@include mat.core-theme($app-theme);
@include mat.all-component-themes($app-theme);

@function theme-spacing($factor) {
  @return (8 * $factor)+px;
}

@mixin mat-icon-size($size: 24px) {
  font-size: $size;
  height: $size;
  width: $size;
}