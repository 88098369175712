/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "assets/styles/spacing.scss";
@import "app-theme.scss";

* {
  margin: 0px;
  padding: 0px;
}

html {
  font-family: Roboto,"Helvetica Neue",sans-serif;
}

.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 0;
  z-index: 100;
}

.custom-modal-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
}

.mat-mdc-text-field-wrapper {
  background-color: white !important;
}

.mdc-text-field {
  padding: 0 0 !important;
}